// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-js": () => import("/opt/render/project/src/src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-pages-blog-js": () => import("/opt/render/project/src/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

